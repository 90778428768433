import React from 'react';

import { Collapse, Flex, Link, Menu } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@firehydrant/design-system';
import { useAuthContext } from 'contexts/AuthContext/AuthContextProvider';
import PropTypes from 'prop-types';

import { useAuthorization } from 'components/common/Authorization/AuthHook';
import UserAvatar from 'components/common/Avatar/UserAvatar';
import OrgRouteLink from 'components/common/OrgRouteLink';
import { useFlags } from 'launchdarkly-react-client-sdk';

const mobileMenuItemProps = {
  w: '100%',
  px: '4',
  py: '2',
  fontSize: '6',
  _hover: {
    bg: 'gray.100',
    transition: '.1s',
    cursor: 'pointer',
  },
  display: 'flex',
  alignItems: 'center',
};

const MobileMenuItem = ({ item, isOpen, onToggle }) => {
  const flags = useFlags();
  const hasSubMenu =
    item?.subMenuItems?.length > 0 || item?.dynamicMobileSubMenu;
  const NavIcon = item.navIcon ? <UserAvatar isSelf ml="2" /> : null;
  return (
    <>
      <Link
        key={`mobile-nav-${item.displayName}`}
        as={item?.subMenuItems ? 'button' : OrgRouteLink}
        to={item.route ? item.route : undefined}
        onClick={(e) => onToggle(e)}
        display="flex"
        w="100%"
        justifyContent="space-between"
        {...mobileMenuItemProps}
      >
        <Flex align="center">
          {item.displayName}
          {NavIcon && NavIcon}
        </Flex>
        <Flex>
          {hasSubMenu && isOpen && <ChevronUpIcon justifySelf="flex-end" />}
          {hasSubMenu && !isOpen && <ChevronDownIcon />}
        </Flex>
      </Link>
      {hasSubMenu && !item?.dynamicMobileSubMenu && (
        <Collapse in={isOpen}>
          {item.subMenuItems
            .filter(
              (item) =>
                (!item.flag || flags[item.flag]) && !flags[item.hideFlag],
            )
            .map((subItem) => (
              <MobileSubMenuItem subItem={subItem} key={subItem.name} />
            ))}
        </Collapse>
      )}
      {item?.dynamicMobileSubMenu && (
        <Collapse in={isOpen}>
          <Menu>{item?.dynamicMobileSubMenu?.()}</Menu>
        </Collapse>
      )}
    </>
  );
};

export const MobileSubMenuItem = ({ subItem }) => {
  const isAuthorized = useAuthorization(subItem.requiredRoles);
  const { handleLogout } = useAuthContext();
  if (!isAuthorized) return null;
  if (!subItem.header) {
    const linkProps = subItem.externalLink
      ? { rel: 'noopener', target: '_blank', href: subItem.href }
      : { to: subItem.href };

    // Sign out link
    if (subItem.href === '/sessions') {
      return (
        <Link
          href="/sessions"
          onClick={handleLogout}
          rel="nofollow"
          as="a"
          ml="4"
          fontSize="7"
          data-method="delete"
          {...linkProps}
          {...mobileMenuItemProps}
        >
          {subItem.name}
        </Link>
      );
    }

    return (
      <Link
        key={`mobile-nav-${subItem.name}`}
        as={subItem.externalLink ? 'a' : OrgRouteLink}
        ml="4"
        fontSize="7"
        {...linkProps}
        {...mobileMenuItemProps}
      >
        {subItem.name}
      </Link>
    );
  }
};

MobileMenuItem.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  item: PropTypes.shape({
    route: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    navIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    subMenuItems: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        href: PropTypes.string,
        ownerOnly: PropTypes.bool,
        externalLink: PropTypes.bool,
      }),
    ),
  }).isRequired,
};

MobileSubMenuItem.propTypes = {
  subItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
    header: PropTypes.bool,
    externalLink: PropTypes.bool,
    href: PropTypes.string,
    requiredRoles: PropTypes.arrayOf(PropTypes.string),
    ownerOnly: PropTypes.bool,
  }).isRequired,
};

export default MobileMenuItem;
