import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  LeftRailHeader,
  LeftRailItem,
  SubMenu,
} from 'components/layout/SideNavigation/SubMenu';

const LeftRailPageLayout = ({ sidebarChildren, children, structure }) => {
  const { isUserOwner, isUserStaffSession } = useUserContext();
  const flags = useFlags();

  return (
    <Flex gap={4}>
      <Flex
        minW="250px"
        display={['none', 'none', 'none', 'flex']}
        borderRight={'1px solid'}
        borderColor="stroke.primary"
        mt={2}
        direction="column"
      >
        <SubMenu>
          {structure.map((subMenuItem) => {
            if (!subMenuItem.flag || flags[subMenuItem?.flag]) {
              if (subMenuItem.header) {
                return (
                  <LeftRailHeader
                    {...subMenuItem}
                    key={subMenuItem.name}
                    owner={isUserOwner}
                  />
                );
              }
              return (
                <LeftRailItem
                  {...subMenuItem}
                  isUserStaffSession={isUserStaffSession}
                  key={subMenuItem.name}
                  owner={isUserOwner}
                  dataMethod={subMenuItem['data-method']}
                  exclusions={subMenuItem.exclusions}
                />
              );
            }
          })}
        </SubMenu>
        {sidebarChildren}
      </Flex>
      <Box w="100%" minWidth="0">
        {children}
      </Box>
    </Flex>
  );
};

LeftRailPageLayout.propTypes = {
  children: PropTypes.element.isRequired,
  structure: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      flag: PropTypes.string,
      header: PropTypes.bool,
      'data-method': PropTypes.string,
    }),
  ).isRequired,
};

export default LeftRailPageLayout;
