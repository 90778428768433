import React from 'react';

import { Icon, Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { BiSolidNotification } from 'react-icons/bi';
import { BsFillTelephoneFill, BsSlack, BsWhatsapp } from 'react-icons/bs';
import { IoChatbubbleEllipsesSharp, IoMail } from 'react-icons/io5';

const icons = {
  email: [
    'Email',
    <Icon as={IoMail} boxSize="14px" color="content.tertiary" />,
  ],
  slack: [
    'Slack',
    <Icon as={BsSlack} boxSize="14px" color="content.tertiary" />,
  ],
  sms: [
    'SMS',
    <Icon
      as={IoChatbubbleEllipsesSharp}
      boxSize="14px"
      color="content.tertiary"
    />,
  ],
  voice: [
    'Voice',
    <Icon as={BsFillTelephoneFill} boxSize="14px" color="content.tertiary" />,
  ],
  apns: [
    'Push',
    <Icon as={BiSolidNotification} boxSize="14px" color="content.tertiary" />,
  ],
  fcm: [
    'Push',
    <Icon as={BiSolidNotification} boxSize="14px" color="content.tertiary" />,
  ],
  whats_app: [
    'WhatsApp',
    <Icon as={BsWhatsapp} boxSize="14px" color="content.tertiary" />,
  ],
};

export const NotificationIcon = ({ type }) => {
  const [tooltipText, icon] = icons[type];

  if (!icon) return null;

  const SpecificIcon = React.forwardRef(({ children, ...rest }, ref) => (
    <span ref={ref} {...rest}>
      {children}
    </span>
  ));

  return (
    <Tooltip placement="top" hasArrow label={tooltipText}>
      <SpecificIcon>{icon}</SpecificIcon>
    </Tooltip>
  );
};

NotificationIcon.propTypes = {
  type: PropTypes.string.isRequired,
};
