import React, { useRef, useState } from 'react';

import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { Flex } from '@firehydrant/design-system';
import { useCookies } from 'react-cookie';
import {
  TbBook,
  TbChevronDown,
  TbMailForward,
  TbShoe,
  TbTimeline,
} from 'react-icons/tb';

import ErrorBoundary from 'components/common/ErrorBoundary';

import { useUserContext } from '../../contexts/UserContext/UserContextProvider';
import {
  AccountAccess,
  DeployPreview,
  Environment,
  Health,
  HideButton,
  InternalToolLink,
  LocalPreview,
  Timing,
} from './components';
import ColorModeToggle from './components/ColorModeToggle';

const InternalToolbar = () => {
  const barRef = useRef(null);
  const [hideTools, setHideTools] = useState(false);
  const [cookies] = useCookies(['laddertruck']);
  const cookieKey = 'use_local_dev';
  const preview = cookies[cookieKey];
  const { currentUser } = useUserContext();
  const environment = window.environment || 'none';
  const development = environment === 'development';

  if (!(currentUser?.isAdminUser || currentUser?.isStaffSession) || hideTools)
    return false;

  return (
    <ErrorBoundary>
      <Flex
        ref={barRef}
        p={2}
        backgroundColor={preview ? 'purple.900' : 'gray.800'}
        color="white"
        justifyContent="space-between"
        display={{ sm: 'none', md: 'flex' }}
      >
        <Flex alignItems="center" gap={2}>
          <Health />
          <Environment />
          <LocalPreview />
          <DeployPreview />
        </Flex>
        <Flex display={{ sm: 'none', '2xl': 'flex' }}>
          <Timing barRef={barRef} />
        </Flex>
        <Flex gap={1}>
          {development && (
            <Flex gap="1" display={{ sm: 'none', xl: 'flex' }}>
              <Menu>
                <MenuButton
                  as={Button}
                  size="xs"
                  px={2}
                  py={1}
                  variant="ghost"
                  height="auto"
                  color="grey.300"
                  _hover={{ bg: '#202020' }}
                  _active={{ bg: '#202020' }}
                >
                  Tools
                  <Icon
                    as={TbChevronDown}
                    color="grey.300"
                    data-testid="task-kebab-menu"
                    boxSize="16px"
                    ml="1"
                  />
                </MenuButton>
                <MenuList>
                  <InternalToolLink
                    href="https://mailhog.local.firehydrant.io"
                    name="Mailhog"
                    iconType={TbMailForward}
                    size="sm"
                  />
                  <InternalToolLink
                    href="http://storybook.local.firehydrant.io"
                    name="Storybook"
                    iconType={TbBook}
                  />
                  <InternalToolLink
                    href="http://sidekiq.local.firehydrant.io"
                    name="Sidekiq"
                    iconType={TbShoe}
                  />
                  <InternalToolLink
                    href="https://temporal.local.firehydrant.io/"
                    name="Temporal"
                    iconType={TbTimeline}
                  />
                </MenuList>
              </Menu>
            </Flex>
          )}
          <ColorModeToggle />
          <HideButton hide={() => setHideTools(true)} />
        </Flex>
      </Flex>
      <AccountAccess />
    </ErrorBoundary>
  );
};

export default InternalToolbar;
