import React from 'react';

import { Menu, MenuButton, MenuList, Portal } from '@chakra-ui/react';
import { Icon } from '@firehydrant/design-system';
import PropTypes from 'prop-types';

const ActionDropdown = ({
  children,
  buttonType = 'button',
  inPortal = false,
}) => {
  // Having trouble with your menu list getting stuck in a parent container and want to escape it?
  // Set inPortal to true and the menu list will be rendered outside of the parent container.
  const menuListContent = inPortal ? (
    <Portal>
      <MenuList backgroundColor="white">{children}</MenuList>
    </Portal>
  ) : (
    <MenuList backgroundColor="white">{children}</MenuList>
  );

  return (
    <Menu placement="bottom-end">
      <MenuButton backgroundColor="transparent" type={buttonType}>
        <Icon
          data-testid="action-options"
          name="menu"
          alignItems="center"
          fontSize="20px"
          variant="ghost"
          color="content.primary"
        />
      </MenuButton>
      {menuListContent}
    </Menu>
  );
};

ActionDropdown.propTypes = {
  buttonType: PropTypes.string,
  inPortal: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ActionDropdown;
