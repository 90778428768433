import React from 'react';

import { IconButton, useColorMode } from '@chakra-ui/react';
import { TbMoon, TbSun } from 'react-icons/tb';

const ColorModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <IconButton
      size="xs"
      px={2}
      py={1}
      variant="ghost"
      height="auto"
      color="grey.300"
      _hover={{ bg: '#202020' }}
      _active={{ bg: '#202020' }}
      onClick={toggleColorMode}
      icon={colorMode === 'light' ? <TbMoon size={16} /> : <TbSun size={16} />}
      aria-label={`Toggle ${colorMode === 'light' ? 'dark' : 'light'} mode`}
    />
  );
};

export default ColorModeToggle;
