import React from 'react';

import { Flex, Tag, Text } from '@chakra-ui/react';
import trackEvent from 'helpers/pawPrints';
import PropTypes from 'prop-types';

import { useAuthorization } from 'components/common/Authorization/AuthHook';
import BetaTag from 'components/common/BetaTag';
import NewTag from 'components/common/NewTag';
import OrgRouteLink from 'components/common/OrgRouteLink';
import { useMultiOrgPaths } from 'components/routes/multiOrgRoutesHelpers';

export function SubMenu({ children, ...props }) {
  return (
    <Flex flexDirection="column" marginRight={3} {...props}>
      {children}
    </Flex>
  );
}

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export function SubMenuHeader({ name, ownerOnly, owner }) {
  if (!owner && ownerOnly) return null;
  return (
    <Flex alignItems="center" minHeight="24px" role="group">
      <Text
        color="grey_deprecated.70"
        flex="1"
        mt="3"
        mb="1"
        ml="8"
        mr="8"
        size="7"
        whiteSpace="nowrap"
        borderBottom={'1px solid'}
        borderColor="grey_deprecated.30"
        textTransform="uppercase"
        fontWeight="500"
        letterSpacing={0.5}
      >
        {name}
      </Text>
    </Flex>
  );
}

SubMenuHeader.propTypes = {
  name: PropTypes.string.isRequired,
  ownerOnly: PropTypes.bool,
  owner: PropTypes.bool,
};

export function LeftRailHeader({ name, requiredRoles }) {
  const isAuthorized = useAuthorization(requiredRoles);
  if (!isAuthorized) return null;
  return (
    <Flex
      alignItems="center"
      minHeight="24px"
      role="group"
      data-testid="left-rail-header"
    >
      <Text
        color="content.primary"
        flex="1"
        mt="3"
        mb="1"
        ml="2"
        mr="8"
        size="5"
        whiteSpace="nowrap"
        fontWeight="1"
      >
        {name}
      </Text>
    </Flex>
  );
}

LeftRailHeader.propTypes = {
  name: PropTypes.string.isRequired,
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
};

export function LeftRailItem({
  name,
  tag,
  href,
  hrefKey,
  owner,
  ownerOnly,
  devOnly,
  isExternalLink,
  internalTools,
  isUserStaffSession,
  hideStaffSession,
  tier,
  tierRestriction,
  rel,
  target,
  dataMethod,
  requiredRoles,
  keepQuery,
  exactMatch,
  isBeta,
  isNew,
  exclusions = [],
}) {
  const currentPath = useMultiOrgPaths();

  // Normalize both current path and href
  const normalizePath = (path) => {
    // Remove trailing slash if it exists
    const cleanPath = path.endsWith('/') ? path.slice(0, -1) : path;
    // Return both variants for comparison
    return {
      withSlash: `${cleanPath}/`,
      withoutSlash: cleanPath,
    };
  };

  const normalizedCurrent = normalizePath(currentPath);
  const normalizedHref = normalizePath(href);

  const isExcluded = (path, exclusionPatterns) => {
    return exclusionPatterns.some((pattern) => {
      const normalizedPattern = pattern.endsWith('/') ? pattern : `${pattern}/`;
      return path.startsWith(normalizedPattern);
    });
  };

  const excluded = isExcluded(normalizedCurrent.withSlash, exclusions);

  const isActive =
    !excluded &&
    ((exactMatch &&
      // Check both slash variations for exact matches
      (normalizedCurrent.withSlash === normalizedHref.withSlash ||
        normalizedCurrent.withoutSlash === normalizedHref.withoutSlash)) ||
      (!exactMatch &&
        (normalizedCurrent.withSlash.startsWith(normalizedHref.withSlash) ||
          normalizedCurrent.withoutSlash.startsWith(
            normalizedHref.withoutSlash,
          ))));

  const isAuthorized = useAuthorization(requiredRoles);
  if (!isAuthorized) return null;

  if ((!owner && ownerOnly) || (hideStaffSession && isUserStaffSession)) {
    return null;
  }
  if (tierRestriction && !tierRestriction.includes(tier)) {
    return null;
  }
  if (devOnly && window.environment !== 'development') {
    return null;
  }

  const link = href || internalTools[hrefKey];
  const linkProps = isExternalLink
    ? {
        as: 'a',
        href: link,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {
        as: OrgRouteLink,
        to: keepQuery ? `${link}${window.location.search}` : link,
      };

  return (
    <Flex
      _active={{
        bg: 'surface.primary',
        border: '1px solid',
        borderColor: 'stroke.primary',
        outline: 'none',
      }}
      _focus={{
        outline: 'none',
      }}
      _hover={{
        bg: 'surface.primary',
        _focus: {
          borderColor: 'white',
        },
      }}
      {...(link ? linkProps : {})}
      alignItems="center"
      variant="unstyled"
      bg={isActive ? 'surface.action.secondary' : null}
      data-testid="left-rail-item"
      cursor="pointer"
      minHeight="24px"
      role="group"
      rel={rel}
      target={target}
      mt={1}
      py="1"
      px="2"
      pr="1"
      borderRadius="6"
      border="1px solid"
      borderColor={isActive ? 'stroke.primary' : 'transparent'}
      data-method={dataMethod}
      onClick={() => {
        trackEvent({
          name: 'Nav Bar Clicked',
          params: {
            pageTitle: name,
          },
        });
      }}
    >
      <Text
        _groupActive={{
          color: 'content.primary',
        }}
        _groupFocus={{
          color: 'content.primary',
        }}
        _groupHover={{
          color: 'content.primary',
        }}
        color={isActive ? 'content.primary' : 'content.tertiary'}
        flex="1"
        mb="0"
        ml="2"
        size="6"
        whiteSpace="nowrap"
        isTruncated
      >
        {name} {isBeta && <BetaTag />}
        {isNew && <NewTag />}
      </Text>
      {tag && (
        <Tag size="sm" ml="2" variant="outline" bg="white">
          {tag}
        </Tag>
      )}
    </Flex>
  );
}

LeftRailItem.propTypes = {
  name: PropTypes.string.isRequired,
  tag: PropTypes.string,
  href: PropTypes.string,
  hrefKey: PropTypes.string,
  owner: PropTypes.bool,
  ownerOnly: PropTypes.bool,
  devOnly: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  internalTools: PropTypes.object,
  isUserStaffSession: PropTypes.bool,
  hideStaffSession: PropTypes.bool,
  tier: PropTypes.string,
  tierRestriction: PropTypes.array,
  rel: PropTypes.string,
  target: PropTypes.string,
  dataMethod: PropTypes.string,
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
  keepQuery: PropTypes.bool,
  exactMatch: PropTypes.bool,
  isBeta: PropTypes.bool,
  exclusions: PropTypes.array,
};
